enum HttpStatusCode {
  INTERNAL_SERVER_ERROR = 500,
  OK = 200,
  MOVED_PERMANENTLY = 301,
  MOVED_TEMPORARILY = 302,
  NOT_FOUND = 404,
  BAD_REQUEST = 400,
}

export default HttpStatusCode
