import React, { useReducer } from 'react'

import { AppStateContext } from '../../lib/app-state'
import {
  IAppState,
  Reducer,
  ReducerActionsTypes,
  IActions,
} from '../../types/app-state'
import { INotification } from '../../types/notification'

const reducer = (state: IAppState, action: IActions): IAppState => {
  const uniqueNotificationsAppState = (
    notification: INotification
  ): IAppState => {
    const exists = state.notifications.some(
      existing =>
        existing.message === notification.message &&
        existing.type === notification.type
    )
    return exists
      ? state
      : { ...state, notifications: [...state.notifications, notification] }
  }

  switch (action.type) {
    case ReducerActionsTypes.SET_TERMS_AND_CONDITIONS:
      return { ...state, termsAndConditions: action.value }
    case ReducerActionsTypes.SET_NOTIFICATION:
      return uniqueNotificationsAppState(action.value)
    case ReducerActionsTypes.CLEAR_NOTIFICATIONS:
      return { ...state, notifications: [] }
    default:
      return state
  }
}

const initialState: IAppState = {
  termsAndConditions: ['', undefined],
  notifications: [],
}

export const AppStateProvider: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const appReducer = useReducer<Reducer>(reducer, initialState)

  return (
    <AppStateContext.Provider value={appReducer}>
      {children}
    </AppStateContext.Provider>
  )
}
