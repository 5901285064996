import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef } from 'react'

import {
  isAccountPath,
  removeAccountPrefix,
} from '@scentregroup/shared/helpers/paths'

import safely from './safely'

const LOCAL_STORAGE_KEY_AUTH_REDIRECT = 'accountAuthRedirect'

enum Sources {
  WEBSITE_CLIENT = 'westfield',
}

const toUrl = (from: string | undefined): string => {
  switch (from) {
    case Sources.WEBSITE_CLIENT:
      return process.env.NEXT_PUBLIC_WEBSITE_CLIENT_URL || ''
    default:
      return ''
  }
}

function saveUrl(path: string, from = ''): void {
  let nextUrl: string = path.indexOf('/') === 0 ? path.slice(1) : path
  nextUrl = `${toUrl(from)}/${nextUrl}`

  safely(() => localStorage.setItem(LOCAL_STORAGE_KEY_AUTH_REDIRECT, nextUrl))
}

const removeUrl = (): void => {
  safely(() => localStorage.removeItem(LOCAL_STORAGE_KEY_AUTH_REDIRECT))
}

function getUrl(
  { remove }: { remove?: boolean } = { remove: true }
): string | undefined {
  const url = safely(() =>
    localStorage.getItem(LOCAL_STORAGE_KEY_AUTH_REDIRECT)
  )

  if (remove) {
    removeUrl()
  }

  return url ?? undefined
}

function hasUrl(): boolean {
  return Boolean(getUrl({ remove: false }))
}

function saveCurrentPath(): void {
  const location = window.location.toString()
  const path = location.replace(new URL(location).origin, '') || '/'
  saveUrl(path, '')
}

type UseAuthRedirectManager = {
  getUrl: typeof getUrl
  hasUrl: typeof hasUrl
  redirectToNext: (fallback?: string) => void
  removeUrl: typeof removeUrl
  saveCurrentPath: typeof saveCurrentPath
  saveFromQuery: () => void
  saveUrl: typeof saveUrl
}

export const useAuthRedirectManager = (): UseAuthRedirectManager => {
  const { query, replace } = useRouter()

  const redirectToNext = useCallback(
    (fallback = '') => {
      const next = getUrl() ?? fallback

      if (isAccountPath(next)) {
        replace(removeAccountPrefix(next) || '/')
      } else {
        location.replace(next)
      }
    },
    [replace]
  )

  const saveFromQuery = useCallback(() => {
    const { path, from = '' } = query

    if (
      typeof path === 'string' &&
      typeof from === 'string' &&
      (path || from)
    ) {
      saveUrl(path, from)
    } else {
      removeUrl()
    }
  }, [query])

  return {
    getUrl,
    hasUrl,
    redirectToNext,
    removeUrl,
    saveCurrentPath,
    saveFromQuery,
    saveUrl,
  }
}

export const useAuthRedirect = (): void => {
  const { redirectToNext } = useAuthRedirectManager()
  const isRedirecting = useRef(false)

  useEffect(() => {
    if (!isRedirecting.current) {
      isRedirecting.current = true
      redirectToNext()
    }
  }, [redirectToNext])
}
