import { ISharedEnvInput } from '../../../../types/shared-env'

export const sharedEnvInput: ISharedEnvInput = {
  NODE_ENV: process.env.NODE_ENV ?? process.env.NEXT_PUBLIC_NODE_ENV,
  STACK_ENV: process.env.STACK_ENV ?? process.env.NEXT_PUBLIC_STACK_ENV,
  GRAPHQL_API_V1: process.env.NEXT_PUBLIC_GRAPHQL_API_V1,
  GRAPHQL_API_V2: process.env.NEXT_PUBLIC_GRAPHQL_API_V2,
  SITEMAP_HOSTNAME: process.env.NEXT_PUBLIC_SITEMAP_HOSTNAME,
  SHARED_COOKIES_DOMAIN: process.env.NEXT_PUBLIC_SHARED_COOKIES_DOMAIN,
  COOKIE_DGC_CART_NAME: process.env.NEXT_PUBLIC_COOKIE_DGC_CART_NAME,
  COOKIE_DGC_CART_PREVIOUS_NAME:
    process.env.NEXT_PUBLIC_COOKIE_DGC_CART_PREVIOUS_NAME,
  COOKIE_DGC_CART_COUNT_NAME:
    process.env.NEXT_PUBLIC_COOKIE_DGC_CART_COUNT_NAME,
  COOKIE_CART_NAME: process.env.NEXT_PUBLIC_COOKIE_CART_NAME,
  COOKIE_CART_PREVIOUS_NAME: process.env.NEXT_PUBLIC_COOKIE_CART_PREVIOUS_NAME,
  COOKIE_CART_COUNT_NAME: process.env.NEXT_PUBLIC_COOKIE_CART_COUNT_NAME,
  COOKIE_SUBURB_NAME: process.env.NEXT_PUBLIC_COOKIE_SUBURB_NAME,
  COOKIE_CENTRE_NAME: process.env.NEXT_PUBLIC_COOKIE_CENTRE_NAME,
  COOKIE_ID_TOKEN_NAME: process.env.NEXT_PUBLIC_COOKIE_ID_TOKEN_NAME,
  COOKIE_ITEMS_PER_PAGE: process.env.NEXT_PUBLIC_COOKIE_ITEMS_PER_PAGE,
  COOKIE_CREDIT_CARD_CHECKBOX:
    process.env.NEXT_PUBLIC_COOKIE_CREDIT_CARD_CHECKBOX,
  COOKIE_DELIVERY_ADD_CHECKBOX:
    process.env.NEXT_PUBLIC_COOKIE_DELIVERY_ADD_CHECKBOX,
  COOKIE_MAX_AGE_IN_SECONDS: process.env.NEXT_PUBLIC_COOKIE_MAX_AGE_IN_SECONDS,
  CONTACT_EMAIL_ADDRESS: process.env.NEXT_PUBLIC_CONTACT_EMAIL_ADDRESS,
  GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
  GTM_PREVIEW_ENV: process.env.NEXT_PUBLIC_GTM_PREVIEW_ENV,
  GTM_PREVIEW_AUTH: process.env.NEXT_PUBLIC_GTM_PREVIEW_AUTH,
  GTM_TRACKING_TIMEOUT: process.env.NEXT_PUBLIC_GTM_TRACKING_TIMEOUT,
  STRIPE_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  RECAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  SENTRY_RELEASE: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
  SENTRY_CLIENT_KEY: process.env.NEXT_PUBLIC_SENTRY_CLIENT_KEY,
  SENTRY_SECURITY_HEADER_REPORT_URI:
    process.env.NEXT_PUBLIC_SENTRY_SECURITY_HEADER_REPORT_URI,
  SENTRY_ENV: process.env.NEXT_PUBLIC_SENTRY_ENV,
  PRIVACY_POLICY_URL_AU: process.env.NEXT_PUBLIC_PRIVACY_POLICY_URL_AU,
  PRIVACY_POLICY_URL_NZ: process.env.NEXT_PUBLIC_PRIVACY_POLICY_URL_NZ,
  TERMS_AND_CONDITIONS_URL: process.env.NEXT_PUBLIC_TERMS_AND_CONDITIONS_URL,
  CONTACT_US: process.env.NEXT_PUBLIC_CONTACT_US,
  FAQ: process.env.NEXT_PUBLIC_FAQ,
  ACCOUNT_SUPPORT_URL: process.env.NEXT_PUBLIC_ACCOUNT_SUPPORT_URL,
  GIFT_CARD_TERMS_AND_CONDITIONS_URL:
    process.env.NEXT_PUBLIC_GIFT_CARD_TERMS_AND_CONDITIONS_URL,
  ALGOLIA_CONTENT_APPLICATION_ID:
    process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
  ALGOLIA_CONTENT_API_KEY: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
  AUTH0_DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  ENABLE_APPLE_PAY: process.env.NEXT_PUBLIC_ENABLE_APPLE_PAY,
}
