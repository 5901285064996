import React from 'react'

import { HubIcon } from '@hub/icon'
import { LinkBox, LinkOverlay } from '@hub/link'
import { Stack } from '@hub/stack'
import { Text } from '@hub/text'
import { Box } from '@hub/box'

type InfoGridTileProps = {
  title: string
  link: string
  description: string
  Icon?: HubIcon
}

const InfoGridTile: React.FC<React.PropsWithChildren<InfoGridTileProps>> = ({
  link,
  title,
  description,
  Icon,
}) => {
  return (
    <LinkBox>
      <LinkOverlay href={link}>
        <Stack
          gap={['spacing-sm', null, 'spacing-md']}
          sx={{
            padding: 'spacing-lg',
          }}
        >
          {Icon && <Icon boxSize={'size-icon-md'} />}
          <Box>
            <Text isBodyText tone={'strong'}>
              {title}
            </Text>
            <Text isBodyText>{description}</Text>
          </Box>
        </Stack>
      </LinkOverlay>
    </LinkBox>
  )
}

export default InfoGridTile
