import { useCallback } from 'react'

import { NotificationType } from '../types/notification'
import { STORAGE_KEY_NEXT_LOAD_NOTIFICATION } from './storage'
import useNotification from './use-notification'

type NextLoadNotification = {
  setNextLoadNotification: (message: string, type?: NotificationType) => void
  cancelNextLoadNotification: () => void
  openNextLoadNotification: () => void
}

/*
  Used to display a notification after the next page load/callback has been executed.
  Recoil state is reset in some load cases, so this provides a more robust solution.
*/
const useNextLoadNotification = (): NextLoadNotification => {
  const { openNotification } = useNotification()

  const setNextLoadNotification = useCallback(
    (message: string, type?: NotificationType) =>
      sessionStorage.setItem(
        STORAGE_KEY_NEXT_LOAD_NOTIFICATION,
        JSON.stringify({ message, type })
      ),
    []
  )

  const cancelNextLoadNotification = useCallback(() => {
    sessionStorage.removeItem(STORAGE_KEY_NEXT_LOAD_NOTIFICATION)
  }, [])

  const openNextLoadNotification = useCallback(() => {
    const nextLoadNotification = sessionStorage.getItem(
      STORAGE_KEY_NEXT_LOAD_NOTIFICATION
    )
    if (nextLoadNotification) {
      const { message, type } = JSON.parse(nextLoadNotification)
      cancelNextLoadNotification()
      openNotification({ message, type })
    }
  }, [openNotification, cancelNextLoadNotification])

  return {
    setNextLoadNotification,
    cancelNextLoadNotification,
    openNextLoadNotification,
  }
}

export default useNextLoadNotification
