export { incBaseAccount } from '@scentregroup/shared/helpers/paths'

export const FORGOT_PASSWORD = 'forgot-password'
export const FORGOT_PASSWORD_SENT = `${FORGOT_PASSWORD}/sent`
export const SENT_PASSWORD = 'sent-password' // identity-client only, use FORGOT_PASSWORD_SENT
export const PASSWORD_RESET = 'password-reset/:token'
export const REGISTER = 'register'
export const MIGRATE = 'migrate'
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const CALLBACK = 'callback'
export const USER_INFO = 'account-info'
export const PARKING_DETAILS = 'parking-details'
export const CONFIRM_EMAIL = 'confirm-email'
export const CHECK_EMAIL = 'check-email'
export const CHECK_MOBILE = 'check-mobile'
export const COOKIE_ERROR = 'cookie-error'
export const NOT_FOUND = '404'
export const ONBOARDING_YOUR_DETAILS = 'your-details'
export const ONBOARDING_SUCCESS = 'success'
export const NEXT = 'next'
export const LOGOUT_NEXT = 'logout/next'
export const ONBOARDING_TERMS_CONDITIONS = 'terms-and-conditions'
export const ONBOARDING_PARKING_CONSENT = 'parking-consent'
// User Info paths
export const PREFERRED_CENTRE = 'preferred-centre'
export const PROFILE = 'profile'
export const PARKING = 'parking'
export const BILLING = 'billing-info'
export const TRANSACTIONS = 'transactions'
export const MANAGE_SUBSCRIPTIONS = 'manage-subscriptions'
export const DELETE_USER = 'delete'
export const ORDERS = 'orders'
export const ORDER_DETAILS = `${ORDERS}/:orderId`
export const ORDER_RETURN_COMPLETE = `complete`
export const PAYMENT_METHOD = 'payment-methods'
export const REWARD = 'rewards'
export const PREFERENCES = 'preferences'
export const RETURNS = 'returns'
export const RETURN_DETAILS = `${RETURNS}/:returnOrderId`
export const ADDRESSES = 'delivery-addresses'
export const CONFIRM_DELETE_ACCOUNT = 'confirm-delete-account'
export const DELETE_ACCOUNT_SUCCESS = 'delete-account-success'
export const HOW_TO_EARN_REWARD = 'how-to-earn-reward'
export const HOW_TO_SPEND_REWARD = 'how-to-spend-reward'
export const REWARD_FAQS_SUPPORT = 'reward-faqs-support'
export const CHECKOUT_FULFILMENT = 'checkout/fulfilment'
export const UNSUBSCRIBE = 'unsubscribe'
export const UNSUBSCRIBE_DIRECT = `${UNSUBSCRIBE}/direct`
export const UNSUBSCRIBE_COMPLETE = `${UNSUBSCRIBE}/complete`
