import * as Sentry from '@sentry/nextjs'

import { INotification, NotificationType } from '../types/notification'

export const onError = (
  error: Error,
  notificationFn?: (values: INotification) => void,
  notificationData?: {
    message?: string
    title?: string
    link?: {
      href: string
      label: string
    }
  }
): void => {
  console.dir(error, { depth: null })
  Sentry.captureException(error)
  if (notificationFn) {
    notificationFn({
      message: notificationData?.message || error.message,
      type: NotificationType.ERROR,
      title: notificationData?.title,
      link: notificationData?.link,
    })
  }
}
