import { alertAnatomy } from '@chakra-ui/anatomy'
import { theme } from '@hub/design-system-base/src/theme'

const baseStyleContainer = {
  height: 'content',
  minHeight: 'size-sm',
  marginX: 'auto',
  marginY: 'spacing-md',
  justifyContent: 'space-between',
}

const baseStyleIcon = {
  width: theme.iconSizes['size-icon-sm'],
}

const baseStyleDescription = {
  lineHeight: 'base',
}

const baseStyle = (props: Record<string, any>) => ({
  container: baseStyleContainer,
  icon: baseStyleIcon,
  description: baseStyleDescription,
})

export const alertTheme = {
  alertAnatomy,
  baseStyle,
}
