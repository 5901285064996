import { IUser } from '../../types/user'

export function shouldAutoAcceptTerms(
  user: Pick<IUser, 'acquisitionSources' | 'westfieldPlus'> | undefined
): boolean {
  const acquisitionSources = user?.acquisitionSources

  // To determine if this is from sign-up, acquisition source must only have
  // one value: westfield-plus-web
  const withWestfieldPlusWeb = Boolean(
    acquisitionSources &&
      acquisitionSources.length === 1 &&
      acquisitionSources.includes('westfield-plus-web')
  )
  const termsConditionsAccepted =
    user?.westfieldPlus?.termsAndConditionsVersion?.toString()

  return !termsConditionsAccepted && withWestfieldPlusWeb
}
