import React from 'react'
import { ModalBody as ChakraModalBody } from '@chakra-ui/react'

import { forwardRef, HubStyleObject } from '@hub/design-system-base'

type ModalBodyProps = {
  sx?: HubStyleObject
}

export const ModalBody = forwardRef<ModalBodyProps, typeof ChakraModalBody>(
  (props, ref) => {
    return <ChakraModalBody ref={ref} {...props} />
  }
)
