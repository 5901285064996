import { Country } from '../country'

export type ICountrySpecificValue<T> = Record<Country, T>

export function makeCountrySpecificValue<T>(
  australia: T,
  newZealand: T
): ICountrySpecificValue<T> {
  return {
    Australia: australia,
    'New Zealand': newZealand,
  }
}

export function resolveCountrySpecificValue<T>(
  value: ICountrySpecificValue<T>,
  country: Country
): T {
  return value[country]
}
