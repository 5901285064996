import React from 'react'
import { ModalOverlay as ChakraModalOverlay } from '@chakra-ui/react'

import { forwardRef } from '@hub/design-system-base'

type ModalOverlayProps = {}

export const ModalOverlay = forwardRef<
  ModalOverlayProps,
  typeof ChakraModalOverlay
>((props, ref) => {
  return <ChakraModalOverlay ref={ref} {...props} />
})
