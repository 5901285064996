import { ApolloError, ApolloQueryResult } from '@apollo/client'
import { atom, selector } from 'recoil'

import { IUser } from '../../types/user'

export const userAtom = atom<{
  data: IUser | undefined
  error: ApolloError | undefined
  refetch:
    | (() => Promise<ApolloQueryResult<{ currentUser: IUser }>>)
    | undefined
}>({
  key: 'userAtom',
  default: {
    data: undefined,
    error: undefined,
    refetch: undefined,
  },
})

export const loadingUserAtom = atom<boolean>({
  key: 'loadingUserAtom',
  default: true,
})

export const termsConditionsVersionAtom = atom<number | undefined>({
  key: 'termsConditionsVersionAtom',
  default: undefined,
})

export const updatedTermsConditionsAtom = atom<boolean>({
  key: 'updatedTermsConditionsAtom',
  default: false,
})

export const updatingTermsConditionsAtom = atom<boolean>({
  key: 'updatingTermsConditionsAtom',
  default: false,
})

export const updateTermsConditionsAutomaticallySelector = selector({
  key: 'updateTermsConditionsAutomaticallySelector',
  get: ({ get }) => {
    const { data: user } = get(userAtom)
    const acquisitonSource = user?.acquisitionSources

    // to determine if this is from signup, acquisition source must only have one value westfield-plus-web
    const withWestfieldPlusWeb =
      acquisitonSource != null &&
      acquisitonSource.length === 1 &&
      acquisitonSource.includes('westfield-plus-web')
    const termsConditionsAccepted =
      user?.westfieldPlus?.termsAndConditionsVersion?.toString()

    return !termsConditionsAccepted && withWestfieldPlusWeb
  },
})

export const parkingConsentAtom = atom<boolean>({
  key: 'parkingConsentAtom',
  default: false,
})
