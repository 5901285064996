import { makeSharedEnv } from '../shared/shared-env/shared-env'
import { sharedEnvInput } from '../shared/shared-env/shared-env-input'
import {
  getOsEnv,
  getOsEnvCountrySpecific,
  normalizePort,
  stackEnvToEnvironment,
  toNumber,
  toBool,
} from './lib/env'

const nodeEnv = getOsEnv('NODE_ENV', 'development')
const rancherEnv = stackEnvToEnvironment(getOsEnv('STACK_ENV'))
const isProduction = nodeEnv === 'production'
const isTest = nodeEnv === 'test'
const isDevelopment = nodeEnv === 'development'

const sharedEnv = makeSharedEnv(sharedEnvInput)

export default {
  rancherEnv,
  nodeEnv,

  isProduction,
  isTest,
  isDevelopment,

  httpProxy: getOsEnv('HTTP_PROXY'),

  server: {
    port: normalizePort(getOsEnv('PORT') || getOsEnv('APP_PORT')),
    processes: toNumber(getOsEnv('NUM_PROCESSES', '1')),

    logLevel: getOsEnv('LOG_LEVEL', 'debug'),
    httpLogRequestHeaders: toBool(getOsEnv('HTTP_LOG_REQUEST_HEADERS')),

    // For express
    serverPath: isProduction
      ? '/opt/account-client/apps/account/build'
      : './build',
    staticPath: isProduction
      ? '/opt/account-cient/apps/account/build/static'
      : './build/static',
  },

  // Shared between client and server. Put env vars that should be accessible
  // to client here. Anything in this object will be **public**!
  sharedEnv,

  // values that get rendered into site configuration
  // these values are public, but not in the raw form in which they appear here
  siteConfiguration: {
    privacyPolicyUrl: getOsEnvCountrySpecific(
      'PRIVACY_POLICY_URL',
      'https://www.westfield.com.au/privacy-policy#westfield-plus-privacy-statement '
    ),
    marketplace: {
      urls: {
        termsAndConditions: getOsEnv(
          'TERMS_AND_CONDITIONS_URL',
          'https://westfield.com.au/terms-and-conditions#gift-cards-online-purchase-terms-and-conditions'
        ),
        faq: getOsEnv(
          'MARKETPLACE_FAQ',
          'https://support.westfield.com.au/hc/en-us/categories/360002841432-Westfield-Direct'
        ),
        directReturnPolicy: getOsEnv(
          'DIRECT_RETURN_POLICY',
          'https://support.westfield.com.au/hc/en-us/categories/360002841432-Westfield-Direct'
        ),
      },
    },
  },

  marketplace: {
    // Basic auth for alpha
    basicAuthUser: getOsEnv('MARKETPLACE_BASIC_AUTH_USER'),
    basicAuthPassword: getOsEnv('MARKETPLACE_BASIC_AUTH_PASSWORD'),
  },
}
