import React from 'react'
import { useRecoilValue } from 'recoil'
import { loadingUserAtom, userAtom } from '../../lib/atoms/user-info'
import { UserDataProfileTracker } from '@scentregroup/shared/analytics/user-data-profile-tracker'

export const UserProfileTracker: React.FC = () => {
  const loading = useRecoilValue(loadingUserAtom)
  const userFetch = useRecoilValue(userAtom)
  return <UserDataProfileTracker loading={loading} user={userFetch?.data} />
}
