import React from 'react'
import { ModalFooter as ChakraModalFooter } from '@chakra-ui/react'

import { forwardRef } from '@hub/design-system-base'

type ModalFooterProps = {}

export const ModalFooter = forwardRef<
  ModalFooterProps,
  typeof ChakraModalFooter
>((props, ref) => {
  return <ChakraModalFooter ref={ref} {...props} />
})
