import React, { useEffect } from 'react'
import { AlertLargeIcon } from '@hub/icon'
import { Box } from '@hub/box'
import { H1 } from '@hub/heading'
import { Text } from '@hub/text'
import { Link } from '@hub/link'
import { onError } from '../../lib/on-error'

type ErrorPageProps = {
  error?: Error
}

const ErrorPage: React.FunctionComponent<
  React.PropsWithChildren<ErrorPageProps>
> = ({ error }) => {
  useEffect(() => {
    if (error) {
      onError(error)
    }
  }, [error])

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      sx={{ gap: '24px' }}
    >
      <AlertLargeIcon boxSize="size-icon-xl" sx={{ marginTop: '32px' }} />
      <H1
        sx={{
          fontSize: ['font-xl', 'font-2xl'],
        }}
      >
        Something went wrong
      </H1>
      <Text
        sx={{
          paddingX: ['16px', '0'],
          fontSize: 'font-md',
          lineHeight: 'base',
          textAlign: 'center',
        }}
      >
        {`We are currently experiencing issues. Please contact us at support@westfield.com.au. Our team has been notified.`}
      </Text>
      <Link href={'/'} hasUnderline={true}>
        Return Home
      </Link>
    </Box>
  )
}

export default ErrorPage
