import React, { useState, useEffect } from 'react'
import { Spinner } from '@hub/spinner'

const DEFAULT_DELAY = 1000

interface IProps {
  delay?: number
  variant?: 'large' | 'default'
  isSeasonal?: boolean
  [key: string]: unknown
}

const SpinnerDelayed: React.FC<React.PropsWithChildren<IProps>> = props => {
  const [showing, setShowing] = useState(false)
  const {
    delay = DEFAULT_DELAY,
    variant = 'large',
    isSeasonal = false,
    ...restProps
  } = props

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowing(true)
    }, delay)

    return () => clearTimeout(timeoutId)
  }, [delay])

  return (
    <>
      {showing && (
        <Spinner isSeasonal={isSeasonal} variant={variant} {...restProps} />
      )}
    </>
  )
}

export default SpinnerDelayed
