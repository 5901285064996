import { gql } from '@apollo/client'

export default gql`
  query TermsAndConditionsVersion($country: String!) {
    cfNationalHomepageCollection(where: { country_contains_all: [$country] }) {
      items {
        termsAndConditionsVersion
      }
    }
  }
`
