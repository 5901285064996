import { join } from 'path'
export { getOsEnvCountrySpecific } from './country-specific'

export function getOsEnv(key: string, defaultValue?: string): string {
  if (typeof process.env[key] === 'undefined') {
    return defaultValue || ''
  }

  return process.env[key] as string
}

export function getOsEnvOptional(key: string): string | undefined {
  return process.env[key]
}

export function getPath(path: string): string {
  return process.env.NODE_ENV === 'production'
    ? join(process.cwd(), path.replace('src/', 'dist/').slice(0, -3) + '.js')
    : join(process.cwd(), path)
}

export function getPaths(paths: string[]): string[] {
  return paths.map(p => getPath(p))
}

export function getOsPath(key: string): string {
  return getPath(getOsEnv(key))
}

export function getOsEnvArray(key: string, delimiter = ','): string[] {
  const value = getOsEnv(key)
  return (value && value.split(delimiter)) || []
}

export function getOsPaths(key: string): string[] {
  return getPaths(getOsEnvArray(key))
}

export function toNumber(value: string | undefined): number | undefined {
  if (!value) {
    return undefined
  }
  const number = parseInt(value, 10)
  if (isNaN(number)) {
    return undefined
  }
  return number
}

export function toBool(value: string | undefined): boolean {
  return value === 'true'
}

export function normalizePort(port: string): number | string | boolean {
  const parsedPort = parseInt(port, 10)
  if (isNaN(parsedPort)) {
    // named pipe
    return port
  }
  if (parsedPort >= 0) {
    // port number
    return parsedPort
  }
  return false
}

export function stackEnvToEnvironment(env?: string): string | undefined {
  switch (env) {
    case 'dev':
      return 'development'
    case 'stg':
      return 'staging'
    case 'prd':
      return 'production'
    default:
      return undefined
  }
}
