export interface INotification {
  message: string
  title?: string
  link?: {
    href: string
    label: string
  }
  type?: NotificationType
}

export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}
