import { useQuery, ApolloError } from '@apollo/client'
import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'

import { GetUserAccountDocument } from '@scentregroup/shared/graphql'

import { IUser } from '../types/user'
import { userAtom, loadingUserAtom } from './atoms/user-info'

const useUser = (): {
  user: IUser | undefined
  error: ApolloError | undefined
  loading: boolean
} => {
  const setUser = useSetRecoilState(userAtom)
  const setUserLoading = useSetRecoilState(loadingUserAtom)

  const { data, error, loading, refetch } = useQuery<{
    currentUser: IUser
  }>(GetUserAccountDocument, {
    context: { checkForSession: true },
  })

  const user = data?.currentUser

  useEffect(() => {
    if (!loading) {
      setUser({ data: user, error, refetch })
      setUserLoading(false)
    }
  }, [error, loading, refetch, setUser, setUserLoading, user])

  return {
    user,
    error,
    loading,
  }
}

export default useUser
