import React, { useEffect, useState } from 'react'
import { useLocation } from '@scentregroup/shared/next/compat/location'
import { BasicPageContext } from '@scentregroup/shared/context'

/*
  Allows the `basic=true|false` url query param to set/unset the 
  BasicPageContext.Provider value as desired.

  Only the first request requires the `basic` query param, after that all
  sub pages will have access to the passed value via sessionStorage.
*/
const BasicPageQueryProvider: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const BASIC_PARAM = 'basic'
  const location = useLocation()
  const [isBasicPage, setIsBasicPage] = useState(false)

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)

    // Default/override basic state based on query param when available
    if (
      queryParams.get(BASIC_PARAM) !== undefined &&
      queryParams.get(BASIC_PARAM) !== null
    ) {
      const queryIsBasic = queryParams.get(BASIC_PARAM) === 'true'

      sessionStorage.setItem(BASIC_PARAM, JSON.stringify(queryIsBasic))
      setIsBasicPage(queryIsBasic)
    } else {
      // Otherwise load basic session storage value if available
      const sessionIsBasic = sessionStorage.getItem(BASIC_PARAM)

      if (sessionIsBasic !== undefined && sessionIsBasic !== null) {
        setIsBasicPage(JSON.parse(sessionIsBasic))
      }
    }
  }, [location.search])

  return (
    <BasicPageContext.Provider value={isBasicPage}>
      {children}
    </BasicPageContext.Provider>
  )
}

export default BasicPageQueryProvider
