import { CfTopStrip } from '../../graphql'

export interface ITopStripProps {
  backgroundImage?: string
  backgroundColour?: string
  foregroundImage: string
  shortDescription: string
  actionText: string
  url: string
  backendTitle: string
}

type CFTopStripPicked = Pick<
  CfTopStrip,
  keyof Omit<ITopStripProps, 'backgroundImage' | 'foregroundImage'>
> & {
  backgroundImage: { url: string | null } | null
  foregroundImage: { url: string | null } | null
}

export const cfTopStripToTopStripProps = (
  cfTopStrip: CFTopStripPicked
): ITopStripProps => ({
  actionText: String(cfTopStrip.actionText),
  foregroundImage: String(cfTopStrip.foregroundImage?.url),
  shortDescription: String(cfTopStrip.shortDescription),
  url: String(cfTopStrip.url),
  ...(cfTopStrip.backgroundImage
    ? { backgroundImage: String(cfTopStrip.backgroundImage.url) }
    : undefined),
  ...(cfTopStrip.backgroundColour
    ? { backgroundColour: String(cfTopStrip.backgroundColour) }
    : undefined),
  backendTitle: String(cfTopStrip.backendTitle),
})
