import { gql } from '@apollo/client'

export default gql`
  query GetOrder($orderId: ID!) {
    currentUser {
      scentregroupId
      marketplaceOrder(orderId: $orderId) {
        id
        orderReference
        totalIncTax
        createdAt
        status
        payment {
          cardType
          last4
          expMonth
          expYear
        }
        billingAddress {
          firstName
          lastName
          email
          phone
          address1
          suburb
          state
          postCode
          country
        }
        shippingAddress {
          firstName
          lastName
          email
          phone
          address1
          suburb
          state
          postCode
          country
        }
        parcels {
          id
          status
          hasRefund
          confirmedAt
          fulfilmentType
          shippingCost
          fulfilmentLocation
          fulfilmentDayRange {
            min
            max
          }
          centreTitle
          centreServicePageURL
          collectedAt
          deliveredAt
          items {
            shop {
              name
            }
            quantity
            priceIncTax
            originalPriceIncTax
            variant {
              id
              title
              brand
              sku
              price
              returnable
              images {
                url
                description
              }
              optionValues {
                id
                optionId
                displayName
                value
              }
            }
            promotions {
              campaignId
              title
            }
          }
        }
      }
    }
  }
`
