import React from 'react'
import { ModalContent as ChakraModalContent } from '@chakra-ui/react'

import { forwardRef, HubStyleObject } from '@hub/design-system-base'

type ModalHeaderProps = {
  sx?: HubStyleObject
}

export const ModalContent = forwardRef<
  ModalHeaderProps,
  typeof ChakraModalContent
>(({ sx, ...props }, ref) => {
  return (
    <ChakraModalContent
      sx={{
        top: '15%',
        maxWidth: '512px',
        paddingY: 'spacing-lg',
        paddingTop: 'spacing-xl',
        paddingX: 'spacing-xl',
        ...sx,
      }}
      ref={ref}
      {...props}
    />
  )
})
