import { NormalizedCacheObject, PossibleTypesMap } from '@apollo/client'
import { get } from 'lodash'

import { isBrowser } from '@scentregroup/shared/env'

import env from '../server/env'
import { staticSharedEnv } from './shared-env/shared-env'

export const getClientGraphQLSchema = (): PossibleTypesMap | undefined => {
  if (isBrowser()) {
    return (window as any).__GRAPHQL_SCHEMA_POSSIBLE_TYPES__
  } else {
    return undefined
  }
}

export const getClientApolloState = (): NormalizedCacheObject => {
  if (isBrowser()) {
    return (window as any).__APOLLO_STATE__
  } else {
    return {}
  }
}

export const getSharedEnv = (keyPath: string): any => {
  let sharedEnv = undefined
  if (isBrowser()) {
    sharedEnv = (window as any).__ENV__
  } else {
    sharedEnv = env.sharedEnv
  }
  if (!sharedEnv) {
    sharedEnv = staticSharedEnv
  }
  return sharedEnv && get(sharedEnv, keyPath)
}

export const isCookieEnabledBrowser = (): boolean =>
  typeof navigator === 'object' && navigator.cookieEnabled === true
