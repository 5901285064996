import { createContext, useContext } from 'react'

import { ISiteConfiguration } from '../types/site-configuration'

export const SiteConfigurationContext = createContext<
  ISiteConfiguration | undefined
>(undefined)
SiteConfigurationContext.displayName = 'SiteConfiguration'

export const useSiteConfiguration = (): ISiteConfiguration =>
  useContext(SiteConfigurationContext) as ISiteConfiguration
