import {
  NormalizedCacheObject,
  ApolloClient,
  useMutation,
  OperationVariables,
  DefaultContext,
  ApolloCache,
  DocumentNode,
  TypedDocumentNode,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client'
import type { Auth0ContextInterface } from '@auth0/auth0-react'
import Cookies from 'universal-cookie'

import { makeApolloClient as sharedMakeApolloClient } from '@scentregroup/shared/apollo-client'

import { getSharedEnv } from './env/shared/env'

interface IMakeApolloClientOptions {
  requestId?: string
  auth0?: Pick<
    Auth0ContextInterface,
    'getAccessTokenSilently' | 'getIdTokenClaims'
  > | null
  cookies?: Partial<{ [key: string]: string }>
  getSessionId?(): string
}

export function makeApolloClient({
  requestId,
  auth0,
  cookies,
  getSessionId,
}: IMakeApolloClientOptions = {}): ApolloClient<NormalizedCacheObject> {
  const urlsByVersion: Record<number, string> = {
    1: getSharedEnv('graphqlApi.v1'),
    2: getSharedEnv('graphqlApi.v2'),
  }

  return sharedMakeApolloClient({
    client: {
      name: 'account-client',
      version: getSharedEnv('sentry.release'),
    },
    operationLogging: process.env.NODE_ENV === 'development',
    defaultVersion: 2,
    urlsByVersion,
    requestId,
    auth0,
    cookies: new Cookies(cookies),
    getSessionId,
  })
}

// The shared apollo client has a policy of "all", which results in the error not being thrown
// Use the following function to have the default apollo behavior instead
export function useSafeMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables, TContext, TCache>
): MutationTuple<TData, TVariables, TContext, TCache> {
  return useMutation(mutation, {
    ...(options || {}),
    context: { version: 2 } as TContext,
    errorPolicy: 'none',
  })
}
