import { Dispatch, ReducerState, ReducerAction } from 'react'

import { ITermsAndConditions } from '../lib/form'
import { INotification } from './notification'

export interface IAppState {
  termsAndConditions: ITermsAndConditions
  notifications: INotification[]
}

interface ITermsAndConditionsAction {
  type: ReducerActionsTypes.SET_TERMS_AND_CONDITIONS
  value: ITermsAndConditions
}

interface ISetNotificationAction {
  type: ReducerActionsTypes.SET_NOTIFICATION
  value: INotification
}

interface IClearNotificationsAction {
  type: ReducerActionsTypes.CLEAR_NOTIFICATIONS
}

export type IActions =
  | ITermsAndConditionsAction
  | ISetNotificationAction
  | IClearNotificationsAction

export type Reducer = (state: IAppState, action: IActions) => IAppState

export type AppStateProviderValue = [
  ReducerState<Reducer>,
  Dispatch<ReducerAction<Reducer>>,
]

export enum ReducerActionsTypes {
  SET_TERMS_AND_CONDITIONS = 'setTermsAndConditions',
  SET_NOTIFICATION = 'setNotification',
  CLEAR_NOTIFICATIONS = 'clearNotifications',
}
