import React from 'react'
import { NextPage } from 'next'
import AccountLayout from '../components/layouts/account-layout'
import InfoGrid from '../components/info-grid'
import withRequiresSession from '../components/with-requires-session'

const Page: NextPage = () => {
  return (
    <AccountLayout pageId={'dashboard-page'} maxWidth={'size-max-fixed'}>
      <InfoGrid />
    </AccountLayout>
  )
}

export default withRequiresSession(Page)
