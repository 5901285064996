import {
  ICountrySpecificValue,
  makeCountrySpecificValue,
} from '@scentregroup/shared/configuration'

import { getOsEnv } from './env'

export function getOsEnvCountrySpecific(
  name: string,
  defaultValue?: string
): ICountrySpecificValue<string> {
  const generic = getOsEnv(`${name}`, defaultValue)
  const australia = getOsEnv(`${name}_AU`, generic)
  const newZealand = getOsEnv(`${name}_NZ`, generic)
  return makeCountrySpecificValue(australia, newZealand)
}
