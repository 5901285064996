import { ApolloProvider } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import React, { useMemo } from 'react'

import useMemoizedPick from '@scentregroup/shared/lib/use-memoized-pick'
import { useSessionId } from '@scentregroup/shared/session-id'

import { makeApolloClient } from '../lib/apollo-client'

const auth0Methods = [
  'getAccessTokenSilently',
  'getAccessTokenWithPopup',
  'getIdTokenClaims',
  'loginWithRedirect',
  'loginWithPopup',
  'logout',
] as const

const CustomApolloProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const rawAuth0 = useAuth0()
  const auth0 = useMemoizedPick(rawAuth0, auth0Methods)
  const { getOrGenerateSessionId } = useSessionId()
  const apollo = useMemo(
    () => makeApolloClient({ auth0, getSessionId: getOrGenerateSessionId }),
    [auth0, getOrGenerateSessionId]
  )
  return <ApolloProvider client={apollo}>{children}</ApolloProvider>
}

export { CustomApolloProvider as ApolloProvider }
