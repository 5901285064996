import React from 'react'
import { ModalHeader as ChakraModalHeader } from '@chakra-ui/react'

import { forwardRef } from '@hub/design-system-base'

type ModalHeaderProps = {}

export const ModalHeader = forwardRef<
  ModalHeaderProps,
  typeof ChakraModalHeader
>((props, ref) => {
  return <ChakraModalHeader ref={ref} {...props} />
})
