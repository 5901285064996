import { createContext, useContext } from 'react'

import { AppStateProviderValue } from '../types/app-state'

export const AppStateContext = createContext<AppStateProviderValue | undefined>(
  undefined
)

export const useAppStateContext = (): AppStateProviderValue =>
  useContext(AppStateContext) as AppStateProviderValue
